import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { environment } from '../../../../environments/environment';
import { catchError, from, mergeMap, Observable, tap } from 'rxjs';
import { User } from '../../../auth/dto/user';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class WebPushService {
  private readonly STORAGE_KEY = 'lastWebPushPermissionRequest';
  private readonly VAPID_PUBLIC_KEY = environment.vapidPublicKey;
  private readonly restPath = `${environment.api}/web-push`;

  constructor(
    private http: HttpClient,
    private swPush: SwPush,
    private authService: AuthService
  ) {
  }

  /**
   * Requests the user to allow notifications.
   * If the user allows, the subscription will be saved in the database.
   * The date of the request will be saved in the local storage.
   * */
  requestPermission(): Observable<User> {
    return from(
      this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY,
      })
    ).pipe(
      mergeMap((sub) => {
        this.setLastPermissionRequestDate();
        return this.updateWebPushSubscription(sub);
      }),
      catchError((error) => {
        console.error('Could not requestPermission to notifications', error.message);
        throw error;
      })
    );
  }

  /**
   * Call the backend to update the subscription.
   *
   * @param subscription The subscription object.
   * @returns The updated user.
   * */
  updateWebPushSubscription(subscription: PushSubscription): Observable<User> {
    return this.http.post<User>(
      `${this.restPath}/updateWebPushSubscription`,
      subscription
    );
  }

  /**
   * Mute notifications for the current user.
   *
   * @param status boolean
   */
  muteNotifications(status: boolean): Observable<User> {
    return this.http
      .post<User>(`${this.restPath}/muteNotifications`, { status })
      .pipe(
        tap(() => {
          this.authService.updateLoggedUser();
        })
      );
  }

  /**
   * Checks if the user has already been asked for permission.
   * It is useful to avoid asking the user multiple times.
   *
   * @param days The number of days to check.
   * If the last request is older than this number or if there is no request, it will return true.
   *
   * @returns boolean
   * */
  subscriptionOlderThanDays(days: number): boolean {
    const lastPermissionRequestDate = this.getLastPermissionRequestDate();
    if (!lastPermissionRequestDate) {
      return true;
    }

    const now = new Date();
    const diff = now.getTime() - lastPermissionRequestDate.getTime();
    const diffInDays = diff / (1000 * 3600 * 24);
    return diffInDays >= days;
  }

  /**
   * Saves the date of the last permission request in the local storage.
   */
  setLastPermissionRequestDate() {
    localStorage.setItem(this.STORAGE_KEY, new Date().toJSON());
  }

  /**
   * Gets the date of the last permission request.
   * If there is no date, it will return null.
   *
   * @returns Date | null
   */
  getLastPermissionRequestDate(): Date | null {
    try {
      const val = localStorage.getItem(this.STORAGE_KEY);
      return val ? new Date(val) : null;
    } catch (_) {
      return null;
    }
  }

  /**
   * The alias for the `SwPush.isEnabled` property.
   *
   * @see {@link https://angular.io/api/service-worker/SwPush#isEnabled}
   * */
  isSwEnabled(): boolean {
    return this.swPush.isEnabled && !this.isIos();
  }

  isNotificationPermissionGranted(): boolean {
    if ('Notification' in window) {
      return Notification.permission === 'granted';
    }

    return false;
  }

  isIos() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
}
