import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})


export class LocalStorageService {
  setItem(key: string, value: LocalStorageType): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): LocalStorageType  {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  isMenuExpanded(): boolean {
    const item = this.getItem(LocalStorageKeys.IS_MENU_EXPANDED);
    if (item === null) {
      return true;
    }
    return !!item;
  }
}

type LocalStorageType = string | number | boolean | object;

export enum LocalStorageKeys {
  IS_MENU_EXPANDED= 'isMenuExpanded',
}
